<template>
  <div class="page">
    <div class="segment">
      <div class="content narrow">

        <div class="content">
          <div class="post column">
            <div class="post-banner">
              <img src="@/assets/pagebanner-missions.jpg">
            </div>

            <div class="post-content-wrapper">
              <div class="post-header">Missions</div>
              <pre class="post-content">
                Before the game starts, your commander will receive the faction's operation orders. The operation orders contain many missions and tasks which the commander will, during the course of the game, proceed to hand out to different units on your team. Here is a list of the most common kinds of objectives that you can encounter during your game and what function each objective has.
              </pre>
            </div>

          </div>

        </div>
      </div>
    </div>

    <div class="segment">
      <div class="content narrow">

        <div class="content">
          <div class="post">
            <div class="image centered-image">
              <img src="@/assets/2025/missions/Ammo.png">
            </div>
            <div class="post-content-wrapper">
              <div class="post-header">Ammo</div>
              <pre class="post-content">
                Ammo boxes allow your team to carry ammo into the field according to the standard rules. Without ammo, your squad may be limited to carrying only bullets in their magazines and nothing to reload in the field. Should an ammunition shortage occur, this will be notified via your team HQ.

                Each box you find must be taken to your HQ for registration.
              </pre>
            </div>
          </div>

          <div class="post">
            <div class="image centered-image">
              <img src="@/assets/2025/missions/Medical.png">
            </div>
            <div class="post-content-wrapper">
              <div class="post-header">Medical boxes</div>
              <pre class="post-content">
                Medical boxes allow medics on your team to carry bandages into the field according to the standard rules.

                Without medical supplies, your team's Medics can be limited to only carrying 1 bandage in the field and thus only performing one "heal" per life/respawn.

                Each box you find must be taken to your HQ for registration.
              </pre>
            </div>
          </div>

          <div class="post">
            <div class="image centered-image">
              <img src="@/assets/2025/missions/Arty.png">
            </div>
            <div class="post-content-wrapper">
              <div class="post-header">Artillery</div>
              <pre class="post-content">
                Artillery ammunition allows your HQ to fire indirect fire and support your team during attack or defense.

                Without artillery ammunition, your HQ cannot use indirect fire support.

                Each box you find must be taken to your HQ for registration.
              </pre>
            </div>
          </div>

          <div class="post">
            <div class="image centered-image">
              <img src="@/assets/2025/missions/CP.png">
            </div>
            <div class="post-content-wrapper">
              <div class="post-header">Capture points</div>
              <pre class="post-content">
                Capture points are tactical points on the map that can be captured by your team to get an extra respawn.

                If the enemy takes over this, your team loses the ability to respawn at that location.

                A capture point is static and cannot be moved. There may be visible capture points on the map from the start of the game and there may be those that must be found.
              </pre>
            </div>
          </div>

          <div class="post">
            <div class="image centered-image">
              <img src="@/assets/2025/missions/Drop.png">
            </div>
            <div class="post-content-wrapper">
              <div class="post-header">Airdrop</div>
              <pre class="post-content">
                Airdrops can be requested by your or opponents' HQ. This contains supplies or other important materials.

                These are visible on the digital map for all teams and on your HQ's digital map.

                When the drop is visible, it means that supplies are within 100 meters on the map where it is marked.

                Both your own and the opponent's soldiers can take the content if it is found.
              </pre>
            </div>
          </div>

          <div class="post">
            <div class="image centered-image">
              <img src="@/assets/2025/missions/Fuel.png">
            </div>
            <div class="post-content-wrapper">
              <div class="post-header">Fuel</div>
              <pre class="post-content">
                Fuel is needed for your team's mechanized unit and vehicles.

                If your teams has a shortage of fuel, Mechanized units are not allowed to drive outside your base until new fuel is brought to the base.

                Every fuel container you find must be taken to your HQ for registration.
              </pre>
            </div>
          </div>

          <div class="post">
            <div class="image centered-image">
              <img src="@/assets/2025/missions/Parts.png">
            </div>
            <div class="post-content-wrapper">
              <div class="post-header">Spare parts</div>
              <pre class="post-content">
                Spare parts are needed for your engineers to be able to repair vehicles in the field and at the base.

                If your teams has a shortage of spare-parts, mechanized units are not allowed to be repaired and must follow the vehicle respawn rules in the base.

                Every spare parts box you find must be taken to your HQ for registration.
              </pre>
            </div>
          </div>

          <div class="post">
            <div class="image centered-image">
              <img src="@/assets/2025/missions/Supply.png">
            </div>
            <div class="post-content-wrapper">
              <div class="post-header">Food & Supply</div>
              <pre class="post-content">
              Food & supply boxes are needed in order for the respawn in your home base to function normally.

              If your team lacks Food & supplies, the respawn time in the home base can be doubled for all players.

              Each box you find must be taken to your HQ for registration
              </pre>
            </div>
          </div>

          <div class="post">
            <div class="image centered-image">
              <img src="@/assets/2025/missions/FAD.png">
            </div>
            <div class="post-content-wrapper">
              <div class="post-header">Fuel & Ammode Depot (FAD)</div>
              <pre class="post-content">
              Located in or just outside your and the opponents base, if you knock it out the opponent loses all their capture/points on the map and can only respawn in the base until they are recaptured.

              This is based on the same digital system as Capturepoints and automatically resets the opponent's respawn.

              If you knock this out at the right moment, it has devastating consequences for the opposing team.
              </pre>
            </div>
          </div>

        </div>

      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Missions'
}
</script>

<style scoped>
.post .image.centered-image { padding-top: 20px; min-width: 150px; max-width: 100%; }
.centered-image img { max-height: 100px; max-width: 100px; }
</style>
