<template>
  <div class="page">
    <div class="segment">
      <div class="content narrow">
     <!--   <div class="header">CONTACT <span class="text-highlight">BERGET</span></div> -->
        <!-- POST 1 -->
        <div class="post column">
          <div class="post-banner">
            <img src="@/assets/pagebanner-contact.jpg">
          </div>
          <div class="post-content-wrapper">
            <div class="post-header">Contact berget</div>
            <div class="post-content">
              If you want to contact Berget Events, please chose the appropriate method below:<br>
              <br>
              General Questions - <a href="mailto:info@berget-events.com">info@berget-events.com</a><br>
              Ticket &amp; Payment Questions - <a href="mailto:payment@berget-events.com">payment@berget-events.com</a><br>

              <br>
              <div class="image-link">
                <img src="/img/insta.png" alt="Instagram">
                <a href="https://www.instagram.com/bergetevents/">Follow us on Instagram</a>
              </div>
              <br>
              <div class="image-link">
                <img src="/img/face.png" alt="Facebook">
                <a href="https://www.facebook.com/Berget-Events-122220751123010/">Follow us on Facebook</a>
              </div><br>
              <div class="image-link">
                <img src="/img/discord.png" alt="Discord">
                <a href="https://discord.gg/NW8TB3EDB9">Join our Discord</a>
              </div>

              <br>
              Phone: our phone service is only open in mid june (during the event).
            </div>

          </div>

        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Contact'
}
</script>

<style scoped>
.image-link { display: flex; flex-direction: row;  align-items: center; }
.image-link img { margin-right: 5px }
</style>
